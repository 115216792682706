import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadApiMap } from './interface/loadApiMap.interface';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public loadMap = new BehaviorSubject<LoadApiMap>({isLoad: false, error: false, message: null});

  constructor() { }
}
