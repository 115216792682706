import { Component, OnInit } from '@angular/core';
import { LoadApiMap } from '@core/in-app/map/services/interface/loadApiMap.interface';
import { MapService } from '@core/in-app/map/services/map.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  title = 'CiudadanoApp';
  constructor(private _mapService: MapService) {}

  async loadGoogleMapsApi(): Promise<LoadApiMap> {
    const apiKey = environment.GOOGLE_MAPS_API_KEY;

    return new Promise<LoadApiMap>((resolve, reject) => {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=visualization`
      script.async = true
      script.defer = true

      script.onload = () => {
        let data: LoadApiMap = {
          isLoad: true,
          error: false,
          message: null
        }
        resolve(data);
      };

      script.onerror = (error) => {
        let data: LoadApiMap = {
          isLoad: false,
          error: true,
          message: Error(`Error cargando la API de Google Maps: ${error}`)
        }
        reject(data);
      };

      document.head.appendChild(script);
    });
  }

  ngOnInit(): void {
    if(this.isMobileDevice()) {
      const events = ['click', 'keypress', 'scroll', 'touchstart'];

      events.forEach(event => {
        document.addEventListener(event, this.requestFullscreen.bind(this), { once: true, passive: true })
      })
    }

    this.loadGoogleMapsApi()
      .then((data) => {
        this._mapService.loadMap.next(data)
      })
      .catch(error => {
        this._mapService.loadMap.next(error)
      })
  }

  // Determina si el host es un dispositivo móvil
  private isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent)
  }

  // Hace la solicitud de pantalla completa
  private requestFullscreen(): void {
    const elem = document.documentElement as HTMLElement & {
      webkitRequestFullscreen?: () => Promise<void>
      msRequestFullscreen?: () => Promise<void>
    };

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }
}
